/**
* Generated automatically at built-time (2024-12-04T13:36:56.781Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "cave-a-vin-black-friday",templateKey: "sites/76-9f39915d-3b12-4168-9564-7d5cdd32d2b0"};